@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
	box-sizing: border-box;
}

html {
	font-family: 'Noto Sans JP', sans-serif;
}

body {
	margin: 0;
	background-color: #3f3f3f;
}

.Header_header__2O1C4 {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 5rem;
	background-color: #8a2b06;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10%;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
	z-index: 10;
}

.Header_main-image__90nQ_ {
	width: 100%;
	height: 25rem;
	z-index: 0;
	overflow: hidden;
}

.Header_main-image__90nQ_ img {
	width: 110%;
	height: 100%;
	object-fit: cover;
	-webkit-transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
	        transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
}

.HeaderCartButton_button__3Iwsi {
	cursor: pointer;
	font: inherit;
	border: none;
	background-color: #4d1601;
	color: white;
	padding: 0.75rem 3rem;
	display: flex;
	justify-content: space-around;
	align-items: center;
	border-radius: 25px;
	font-weight: bold;
}

.HeaderCartButton_button__3Iwsi:hover,
.HeaderCartButton_button__3Iwsi:active {
	background-color: #2c0d00;
}

.HeaderCartButton_icon__2FLrX {
	width: 1.35rem;
	height: 1.35rem;
	margin-right: 0.5rem;
}

.HeaderCartButton_badge__22xm1 {
	background-color: #b94517;
	padding: 0.25rem 1rem;
	border-radius: 25px;
	margin-left: 1rem;
	font-weight: bold;
}

.HeaderCartButton_button__3Iwsi:hover .HeaderCartButton_badge__22xm1,
.HeaderCartButton_button__3Iwsi:active .HeaderCartButton_badge__22xm1 {
	background-color: #92320c;
}

.HeaderCartButton_bump__XKXfA {
	-webkit-animation: HeaderCartButton_bump__XKXfA 300ms ease-out;
	        animation: HeaderCartButton_bump__XKXfA 300ms ease-out;
}

@-webkit-keyframes HeaderCartButton_bump__XKXfA {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	10% {
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}
	30% {
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);
	}
	50% {
		-webkit-transform: scale(1.15);
		        transform: scale(1.15);
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@keyframes HeaderCartButton_bump__XKXfA {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	10% {
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}
	30% {
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);
	}
	50% {
		-webkit-transform: scale(1.15);
		        transform: scale(1.15);
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

.Card_card__1te4P {
	padding: 1rem;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
	border-radius: 14px;
	background-color: white;
	overflow: auto;
}

.Input_input__HkNJD {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
}

.Input_input__HkNJD label {
	font-weight: bold;
	margin-right: 1rem;
}

.Input_input__HkNJD input {
	width: 3rem;
	border-radius: 5px;
	border: 1px solid #ccc;
	font: inherit;
	padding-left: 0.5rem;
}

.Modal_backdrop__3iXHD {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 20;
	background-color: rgba(0, 0, 0, 0.75);
}

.Modal_modal__30MRu {
	position: fixed;
	top: 5vh;
	left: 5%;
	width: 90%;
	background-color: white;
	padding: 1rem;
	border-radius: 14px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
	z-index: 30;
	-webkit-animation: Modal_slide-down__294fH 300ms ease-out forwards;
	        animation: Modal_slide-down__294fH 300ms ease-out forwards;
}

@media (min-width: 768px) {
	.Modal_modal__30MRu {
		width: 40rem;
		left: calc(50% - 20rem);
	}
}

@-webkit-keyframes Modal_slide-down__294fH {
	from {
		opacity: 0;
		-webkit-transform: translateY(-3rem);
		        transform: translateY(-3rem);
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes Modal_slide-down__294fH {
	from {
		opacity: 0;
		-webkit-transform: translateY(-3rem);
		        transform: translateY(-3rem);
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

.AvailableMeals_meals__2ZLCP {
	max-width: 60rem;
	width: 90%;
	margin: 2rem auto;
	-webkit-animation: AvailableMeals_meals-appear__ONwLi 1s ease-out forwards;
	        animation: AvailableMeals_meals-appear__ONwLi 1s ease-out forwards;
}

.AvailableMeals_MealsLoading__UmYIn {
	text-align: center;
	color: white;
}

.AvailableMeals_MealsError__38x-D {
	text-align: center;
	color: crimson;
}
.AvailableMeals_meals__2ZLCP ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

@-webkit-keyframes AvailableMeals_meals-appear__ONwLi {
	from {
		opacity: 0;
		-webkit-transform: translateY(3rem);
		        transform: translateY(3rem);
	}

	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes AvailableMeals_meals-appear__ONwLi {
	from {
		opacity: 0;
		-webkit-transform: translateY(3rem);
		        transform: translateY(3rem);
	}

	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

.MealsInputForm_form__3kMuX {
	text-align: right;
}

.MealsInputForm_form__3kMuX button {
	font: inherit;
	cursor: pointer;
	background-color: #8a2b06;
	border: 1px solid #8a2b06;
	color: white;
	padding: 0.25rem 2rem;
	border-radius: 20px;
	font-weight: bold;
}

.MealsInputForm_form__3kMuX button:hover,
.MealsInputForm_form__3kMuX button:active {
	background-color: #641e03;
	border-color: #641e03;
}

.MealsItem_meal__KuLUr {
	display: flex;
	justify-content: space-between;
	margin: 1rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid #ccc;
}

.MealsItem_meal__KuLUr h3 {
	margin: 0 0 0.25rem 0;
}

.MealsItem_description__3N6-N {
	font-style: italic;
}

.MealsItem_price__1K73h {
	margin-top: 0.25rem;
	font-weight: bold;
	color: #ad5502;
	font-size: 1.25rem;
}

.MealsSummary_summary__22zUX {
	text-align: center;
	max-width: 45rem;
	width: 90%;
	margin: auto;
	margin-top: -10rem;
	position: relative;
	background-color: #383838;
	color: white;
	border-radius: 14px;
	padding: 1rem;
	box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25);
}

.MealsSummary_summary__22zUX h2 {
	font-size: 2rem;
	margin-top: 0;
}

.Cart_cart-items__2pjQw {
	list-style: none;
	margin: 0;
	padding: 0;
	max-height: 15rem;
	overflow: auto;
}

.Cart_total__3pLin {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: bold;
	font-size: 1.5rem;
	margin: 1rem 0;
}

.Cart_actions__1GOmr {
	text-align: right;
}

.Cart_actions__1GOmr button {
	font: inherit;
	cursor: pointer;
	background-color: transparent;
	border: 1px solid #8a2b06;
	padding: 0.5rem 2rem;
	border-radius: 25px;
	margin-left: 1rem;
}

.Cart_actions__1GOmr button:hover,
.Cart_actions__1GOmr button:active {
	background-color: #5a1a01;
	border-color: #5a1a01;
	color: white;
}

.Cart_actions__1GOmr .Cart_button--alt__1Z2QQ {
	color: #8a2b06;
}

.Cart_actions__1GOmr .Cart_button__fw1-t {
	background-color: #8a2b06;
	color: white;
}

.CartItem_cart-item__1B1_q {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid #8a2b06;
	padding: 1rem 0;
	margin: 1rem 0;
}

.CartItem_cart-item__1B1_q h2 {
	margin: 0 0 0.5rem 0;
	color: #363636;
}

.CartItem_summary__3FMDj {
	width: 10rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.CartItem_price__2Nl0j {
	font-weight: bold;
	color: #8a2b06;
}

.CartItem_amount__jUKEC {
	font-weight: bold;
	border: 1px solid #ccc;
	padding: 0.25rem 0.75rem;
	border-radius: 6px;
	color: #363636;
}

.CartItem_actions__UwVPJ {
	display: flex;
	flex-direction: column;
}

@media (min-width: 768px) {
	.CartItem_actions__UwVPJ {
		flex-direction: row;
	}
}

.CartItem_cart-item__1B1_q button {
	font: inherit;
	font-weight: bold;
	font-size: 1.25rem;
	color: #8a2b06;
	border: 1px solid #8a2b06;
	width: 3rem;
	text-align: center;
	border-radius: 6px;
	background-color: transparent;
	cursor: pointer;
	margin-left: 1rem;
	margin: 0.25rem;
}

.CartItem_cart-item__1B1_q button:hover,
.CartItem_cart-item__1B1_q button:active {
	background-color: #8a2b06;
	color: white;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-thumb {
	background: #b3afb3;
	border-radius: 30px;
}
::-webkit-scrollbar-thumb:hover {
	background: #b3afb3;
}
::-webkit-scrollbar-track {
	background: #f0f0f0;
	border-radius: 0px;
	box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}

.Checkout_form__26gr1 {
	margin: 0.2rem 0;
	max-height: 20rem;
	overflow: auto;
}

.Checkout_control__2qGfb {
	margin-bottom: 0.5rem;
}

.Checkout_control__2qGfb label {
	font-weight: bold;
	margin-bottom: 0.25rem;
	display: block;
}

.Checkout_control__2qGfb input {
	font: inherit;
	border: 1px solid #ccc;
	border-radius: 4px;
	width: 20rem;
	max-width: 100%;
}

.Checkout_actions__2X_6_ {
	display: flex;
	justify-content: flex-end;
	grid-gap: 1rem;
	gap: 1rem;
}

.Checkout_actions__2X_6_ button {
	font: inherit;
	color: #5a1a01;
	cursor: pointer;
	background-color: transparent;
	border: none;
	border-radius: 25px;
	padding: 0.5rem 2rem;
}

.Checkout_actions__2X_6_ button:hover,
.Checkout_actions__2X_6_ button:active {
	background-color: #ffe6dc;
}

.Checkout_actions__2X_6_ .Checkout_submit__2BFZZ {
	border: 1px solid #5a1a01;
	background-color: #5a1a01;
	color: white;
}

.Checkout_actions__2X_6_ .Checkout_submit__2BFZZ:hover,
.Checkout_actions__2X_6_ .Checkout_submit__2BFZZ:active {
	background-color: #7a2706;
}

.Checkout_invalid__2gAN9 label {
	color: #ca3e51;
}

.Checkout_invalid__2gAN9 input {
	border-color: #aa0b20;
	background-color: #ffeff1;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
	background: #ccc;
	color: #292929;
	border-color: #ccc;
	cursor: not-allowed;
}

