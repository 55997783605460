.cart-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid #8a2b06;
	padding: 1rem 0;
	margin: 1rem 0;
}

.cart-item h2 {
	margin: 0 0 0.5rem 0;
	color: #363636;
}

.summary {
	width: 10rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.price {
	font-weight: bold;
	color: #8a2b06;
}

.amount {
	font-weight: bold;
	border: 1px solid #ccc;
	padding: 0.25rem 0.75rem;
	border-radius: 6px;
	color: #363636;
}

.actions {
	display: flex;
	flex-direction: column;
}

@media (min-width: 768px) {
	.actions {
		flex-direction: row;
	}
}

.cart-item button {
	font: inherit;
	font-weight: bold;
	font-size: 1.25rem;
	color: #8a2b06;
	border: 1px solid #8a2b06;
	width: 3rem;
	text-align: center;
	border-radius: 6px;
	background-color: transparent;
	cursor: pointer;
	margin-left: 1rem;
	margin: 0.25rem;
}

.cart-item button:hover,
.cart-item button:active {
	background-color: #8a2b06;
	color: white;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-thumb {
	background: #b3afb3;
	border-radius: 30px;
}
::-webkit-scrollbar-thumb:hover {
	background: #b3afb3;
}
::-webkit-scrollbar-track {
	background: #f0f0f0;
	border-radius: 0px;
	box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}
